import React from 'react';
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';

import theme from '../../../constants/themes';
import { Typography } from '@mui/material';

const CustomTab = styled(Tab, {
  shouldForwardProp: prop =>
    prop !== '$colorscheme' && prop !== '$isactive' && prop !== '$issub',
})<{ $colorscheme: 'light' | 'dark'; $isactive: boolean; $issub: boolean }>(
  ({ $colorscheme, $isactive, $issub }) => `
    &&.MuiButtonBase-root {
      align-items: flex-start;
      max-width: 300px;
      opacity: 1;
      color: ${
        $colorscheme === 'light' ? theme.colors.black : theme.colors.white
      };
      text-transform: none;
      font-size: 18px;
      line-height: 31px;
      text-decoration: ${$isactive ? 'underline' : 'none'};
    }

    @media ${theme.breakpoints.mobile} {
      &&.MuiButtonBase-root {
        padding-bottom: ${$isactive ? '1px' : '0'};
      }
    }

    @media ${theme.breakpoints.pc} {
      &&.MuiButtonBase-root {
        font-size: ${$issub ? '21px' : '24px'};
        padding-bottom: ${$issub ? '22px' : '30px'};
      }
    }
  `,
);

const CustomTabLabel = styled(Typography)`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

type Props = {
  value?: string;
  label?: string;
  colorScheme?: 'light' | 'dark';
  isActive?: boolean;
  isSub?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

const Component = ({
  value,
  label,
  colorScheme = 'light',
  isActive = false,
  isSub = false,
  onClick,
}: Props): React.ReactElement => {
  return (
    <CustomTab
      value={value}
      label={<CustomTabLabel as="span">{label}</CustomTabLabel>}
      title={label}
      onClick={onClick}
      $colorscheme={colorScheme}
      $isactive={isActive}
      $issub={isSub}
      disableRipple
    />
  );
};

export default Component;
