import { createContext, useContext } from 'react';
import { Item } from '../../domain/entities/item';

export type ItemHooks = {
  useItems: () => {
    fetchItemsByCategoryOrSubcategory: (
      categoryId: string,
      subcategoryId?: string,
    ) => Promise<Item[]>;
    fetchItemsByRestaurant: (restaurantId: string) => Promise<Item[]>;
  };
};

export const ItemHooksContext = createContext<ItemHooks | null>(null);

export const useItemHooks = (): ItemHooks => {
  const context = useContext(ItemHooksContext);
  if (!context) {
    throw new Error(`Context not instantiated`);
  }

  return context;
};
