import { Restaurant } from '../../../domain/entities/restaurant';
import RestaurantRepository from '../../ports/RestaurantRepository';

export type fetchRestaurantByNameUseCase = (
  name: string,
) => Promise<Restaurant>;

export const buildFetchRestaurantByName = (dependencies: {
  restaurantRepo: RestaurantRepository;
}): fetchRestaurantByNameUseCase => {
  const { restaurantRepo } = dependencies;

  const fetchRestaurantByName: fetchRestaurantByNameUseCase = async (
    name: string,
  ) => await restaurantRepo.findRestaurantByName(name);

  return fetchRestaurantByName;
};
