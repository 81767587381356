import React from 'react';
import { useCalendarHooks } from '../../../hooks/calendar';
import { useQuery } from 'react-query';
import { CalendarTemplate } from '../../templates/Calendar';
import { useRestaurantHooks } from '../../../hooks/restaurant';

type Props = {};

const Component = ({}: Props): React.ReactElement => {
  const { useRestaurants } = useRestaurantHooks();
  const { fetchRestaurantByName } = useRestaurants();

  const { data: mugiRestaurant } = useQuery(
    ['restaurant-mugi'],
    async () => await fetchRestaurantByName('mugi'),
    {
      refetchOnMount: true,
    },
  );

  const { data: bakuRestaurant } = useQuery(
    ['restaurant-baku'],
    async () => await fetchRestaurantByName('BAKU'),
    {
      refetchOnMount: true,
    },
  );

  const { useFetchCalendars } = useCalendarHooks();
  const { fetchCalendars } = useFetchCalendars();

  const { data: calendars, isLoading, isSuccess } = useQuery(
    ['calendar-lists'],
    async () => {
      return await fetchCalendars();
    },
    {
      refetchOnMount: true,
      enabled: !!mugiRestaurant && !!bakuRestaurant,
    },
  );

  const formattedCalendars =
    calendars?.map(calendar => {
      return {
        id: calendar.id,
        img_url: calendar.img_url,
        restaurant_id: calendar.restaurant_id,
      };
    }) || [];

  const mugiCalendar = calendars?.find(
    calendar => calendar.restaurant_id == mugiRestaurant?.id,
  );

  const bakuCalendar = calendars?.find(
    calendar => calendar.restaurant_id == bakuRestaurant?.id,
  );

  return (
    <CalendarTemplate
      isSuccess={isSuccess}
      isLoading={isLoading}
      bakuCalendar={bakuCalendar}
      mugiCalendar={mugiCalendar}
      calendars={formattedCalendars}
    />
  );
};

export default Component;
