import { useCallback } from 'react';
import { CategoryHooks } from '.';
import { useDependencies } from '..';
import { Category } from '../../domain/entities/category';

export const useCategories: CategoryHooks['useCategories'] = (): {
  fetchCategoriesByRestaurant: (restaurantId: string) => Promise<Category[]>;
} => {
  const { categoryInteractor } = useDependencies();

  const fetchCategoriesByRestaurant = useCallback(
    async (restaurantId: string) => {
      const categories = await categoryInteractor.fetchCategoriesByRestaurant(
        restaurantId,
      );

      return categories;
    },
    [],
  );

  return { fetchCategoriesByRestaurant };
};
