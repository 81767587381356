import React, { useState } from 'react';
import { styled, StyledComponentProps } from '@mui/material/styles';
import { useMediaQuery, useTheme } from '@mui/material';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as customTheme from '../../../constants/themes';

type CustomSwitcherProps = {} & StyledComponentProps<'root'> & {
    ismugi: boolean;
    ispcscreen: boolean;
  };

const CustomSwitcher = styled(Switch)<CustomSwitcherProps>(
  ({ ismugi, ispcscreen }) => ({
    width: ispcscreen ? '105px' : '80px',
    height: ispcscreen ? '44px' : '32px',
    padding: 0,
    '& .MuiSwitch-switchBase': {
      margin: 0,
      marginTop: 2,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: ismugi
          ? customTheme.default.colors.white
          : customTheme.default.colors.black,
        transform: ispcscreen ? 'translateX(50px)' : 'translateX(38px)',
        '& .MuiSwitch-thumb:before': {
          padding: ispcscreen ? 8 : 6,
          marginLeft: ispcscreen ? 8 : 6,
          content: '"EN"',
        },
        '& + .MuiSwitch-track': {
          backgroundColor: ismugi
            ? customTheme.default.colors.tabBgMugi
            : customTheme.default.colors.tabBgBaku,
          border: ismugi
            ? `thin solid ${customTheme.default.colors.drawerBg}`
            : `thin solid ${customTheme.default.colors.white}`,
          opacity: 1,
          fontFamily: `"${customTheme.default.fonts.primary}"`,
        },
      },
    },
    '& .MuiSwitch-thumb': {
      width: ispcscreen ? 52.5 : 40,
      height: ispcscreen ? 38.5 : 28,
      borderRadius: 32,
      backgroundColor: ismugi
        ? customTheme.default.colors.drawerBg
        : customTheme.default.colors.white,
      margin: 0,
      fontFamily: `"${customTheme.default.fonts.primary}"`,
      color: ismugi
        ? customTheme.default.colors.white
        : customTheme.default.colors.black,
      '&:before': {
        position: 'absolute',
        padding: ispcscreen ? 8 : 6,
        marginLeft: ispcscreen ? 8 : 6,
        content: '"JP"',
        fontSize: ispcscreen ? '16px' : '14px',
        fontWeight: 600,
        fontFamily: `"${customTheme.default.fonts.primary}"`,
      },
    },
    '& .MuiSwitch-track': {
      height: 'auto',
      opacity: 1,
      backgroundColor: ismugi
        ? customTheme.default.colors.tabBgMugi
        : customTheme.default.colors.tabBgBaku,
      border: ismugi
        ? `thin solid ${customTheme.default.colors.drawerBg}`
        : `thin solid ${customTheme.default.colors.white}`,
      borderRadius: 20 / 1,
      marginLeft: 4,
      fontFamily: `"${customTheme.default.fonts.primary}"`,
      '&:before': {
        content: '"JP"',
        top: '50%',
        color: ismugi
          ? customTheme.default.colors.drawerBg
          : customTheme.default.colors.white,
        fontSize: ispcscreen ? '16px' : '14px',
        fontWeigt: 600,
        fontFamily: `"${customTheme.default.fonts.primary}"`,
        right: ispcscreen ? '65%' : '60%',
        transform: 'translateY(-50%)',
        position: 'absolute',
      },
      '&:after': {
        content: '"EN"',
        top: '50%',
        color: ismugi
          ? customTheme.default.colors.drawerBg
          : customTheme.default.colors.white,
        fontSize: ispcscreen ? '16px' : '14px',
        fontWeigt: 600,
        fontFamily: `"${customTheme.default.fonts.primary}"`,
        left: '65%',
        transform: 'translateY(-50%)',
        position: 'absolute',
      },
    },
  }),
);

type Props = {
  defaultLanguage: string | 'EN' | 'JP';
  isMugi?: boolean;
  onSwitch: (newLanguage: 'JP' | 'EN') => void;
};

const Component = ({
  defaultLanguage = 'JP',
  isMugi = true,
  onSwitch,
}: Props): React.ReactElement => {
  const theme = useTheme();
  const pcScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const [lang, setLang] = useState(defaultLanguage);

  const handleLangChange = () => {
    const newLang = lang === 'JP' ? 'EN' : 'JP';
    setLang(newLang);
    onSwitch(newLang);
  };

  return (
    <FormControlLabel
      control={
        <CustomSwitcher
          checked={lang === 'EN'}
          size="medium"
          onChange={handleLangChange}
          ismugi={isMugi}
          ispcscreen={pcScreen}
        />
      }
      label=""
    />
  );
};

export default Component;
