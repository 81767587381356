import React from 'react';
import GlobalStateProvider from './global/Provider';
import { AuthHooksContext } from './auth';
import { TodoHooksContext } from './todo';
import { CalendarHooksContext } from './calendar';
import { RestaurantHooksContext } from './restaurant';
import { CategoryHooksContext } from './category';
import { ItemHooksContext } from './item';
import * as authHooks from './auth/hooks';
import * as todoHooks from './todo/hooks';
import * as calendarHooks from './calendar/hooks';
import * as restaurantHooks from './restaurant/hooks';
import * as categoryHooks from './category/hooks';
import * as itemHooks from './item/hooks';
import { dependencies, Dependencies } from './dependencies';

export const DependenciesContext = React.createContext<Dependencies | null>(
  null,
);

export const useDependencies = (): Dependencies => {
  const context = React.useContext(DependenciesContext);
  if (!context) {
    throw new Error(`Context not instantiated`);
  }
  return context;
};

type Props = {
  children?: React.ReactElement | React.ReactElement[];
};

const Provider = ({ children }: Props): React.ReactElement => {
  return (
    <DependenciesContext.Provider value={dependencies}>
      <GlobalStateProvider>
        {/* put all contexts with hooks starting here */}
        <AuthHooksContext.Provider value={authHooks}>
          <TodoHooksContext.Provider value={todoHooks}>
            <CalendarHooksContext.Provider value={calendarHooks}>
              <RestaurantHooksContext.Provider value={restaurantHooks}>
                <CategoryHooksContext.Provider value={categoryHooks}>
                  <ItemHooksContext.Provider value={itemHooks}>
                    {children}
                  </ItemHooksContext.Provider>
                </CategoryHooksContext.Provider>
              </RestaurantHooksContext.Provider>
            </CalendarHooksContext.Provider>
          </TodoHooksContext.Provider>
        </AuthHooksContext.Provider>
      </GlobalStateProvider>
    </DependenciesContext.Provider>
  );
};

export default Provider;
