import { useCallback } from 'react';
import { ItemHooks } from '.';
import { useDependencies } from '..';
import { Item } from '../../domain/entities/item';

export const useItems: ItemHooks['useItems'] = (): {
  fetchItemsByCategoryOrSubcategory: (
    categoryId: string,
    subcategoryId?: string,
  ) => Promise<Item[]>;
  fetchItemsByRestaurant: (restaurantId: string) => Promise<Item[]>;
} => {
  const { itemInteractor } = useDependencies();

  const fetchItemsByCategoryOrSubcategory = useCallback(
    async (categoryId: string, subcategoryId?: string) => {
      const items = await itemInteractor.fetchItemsByCategoryOrSubcategory(
        categoryId,
        subcategoryId,
      );

      return items;
    },
    [],
  );

  const fetchItemsByRestaurant = useCallback(async (restaurantId: string) => {
    const items = await itemInteractor.fetchItemsByRestaurant(restaurantId);

    return items;
  }, []);

  return { fetchItemsByCategoryOrSubcategory, fetchItemsByRestaurant };
};
