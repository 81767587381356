import { app } from '../../firebase';
import {
  Firestore,
  collection,
  query,
  where,
  orderBy,
  getDocs,
  getFirestore,
} from 'firebase/firestore';
import {
  FirebaseStorage,
  getDownloadURL,
  getStorage,
  ref,
} from 'firebase/storage';

import ItemRepository from '../../usecases/ports/ItemRepository';
import { Item, ItemVariationData } from '../../domain/entities/item';

export default class ItemRepositoryImpl implements ItemRepository {
  firestore: Firestore;
  firebaseStorage: FirebaseStorage;
  collectionName: string;
  storageBasePath: string;

  constructor() {
    this.firestore = getFirestore(app);
    this.firebaseStorage = getStorage(app);
    this.collectionName = 'items';
    this.storageBasePath = 'item-images';
  }

  fetchItemsByCategoryOrSubcategory = async (
    categoryId: string,
    subcategoryId?: string,
  ): Promise<Item[]> => {
    const collectionRef = collection(this.firestore, this.collectionName);
    const itemsQuery = query(
      collectionRef,
      subcategoryId && subcategoryId.length > 0
        ? where('subcategory_id', '==', subcategoryId)
        : where('category_id', '==', categoryId),
      where('is_public', '==', true),
      orderBy('priority', 'asc'),
    );

    try {
      const querySnapshot = await getDocs(itemsQuery);

      const fetchItemDataPromises = querySnapshot.docs.map(async snapshot => {
        const data = snapshot.data();

        let imgUrl = data.img_url;
        if (imgUrl && imgUrl.length > 0) {
          const storageRef = ref(this.firebaseStorage, data.img_url);
          imgUrl = await getDownloadURL(storageRef);
        }

        const formattedVariations = data.variations.map(
          (variation: ItemVariationData) => {
            return {
              ...variation,
              nameEn: variation.name_en,
            };
          },
        );

        return {
          id: snapshot.id,
          createdAt: data.createdAt,
          updatedDate: data.updatedDate,
          restaurantId: data.restaurant_id,
          name: data.name,
          categoryId: data.category_id,
          subcategoryId: data.subcategory_id,
          variations: formattedVariations,
          isPublic: data.is_public,
          nameEn: data.name_en,
          description: data.description,
          descriptionEn: data.description_en,
          imgUrl: imgUrl,
          beerType: data.beer_type,
          abv: data.abv,
          ibu: data.ibu,
          madeIn: data.made_in,
          breweryName: data.brewery_name,
          beerTypeEn: data.beer_type_en,
          madeInEn: data.made_in_en,
          breweryNameEn: data.brewery_name_en,
          priority: data.priority,
        };
      });

      const fetchedItems = await Promise.all(fetchItemDataPromises);
      return fetchedItems;
    } catch (error) {
      console.error('Error fetching items:', error);
      throw error;
    }
  };

  fetchAllItemsOfRestaurant = async (restaurantId: string): Promise<Item[]> => {
    const collectionRef = collection(this.firestore, this.collectionName);
    const itemsQuery = query(
      collectionRef,
      where('restaurant_id', '==', restaurantId),
      where('is_public', '==', true),
      orderBy('priority', 'asc'),
    );

    try {
      const querySnapshot = await getDocs(itemsQuery);

      const fetchItemDataPromises = querySnapshot.docs.map(async snapshot => {
        const data = snapshot.data();

        let imgUrl = data.img_url;
        if (imgUrl && imgUrl.length > 0) {
          const storageRef = ref(this.firebaseStorage, data.img_url);
          imgUrl = await getDownloadURL(storageRef);
        }

        const formattedVariations = data.variations.map(
          (variation: ItemVariationData) => {
            return {
              ...variation,
              nameEn: variation.name_en,
            };
          },
        );

        return {
          id: snapshot.id,
          createdAt: data.createdAt,
          updatedDate: data.updatedDate,
          restaurantId: data.restaurant_id,
          name: data.name,
          categoryId: data.category_id,
          subcategoryId: data.subcategory_id,
          variations: formattedVariations,
          isPublic: data.is_public,
          nameEn: data.name_en,
          description: data.description,
          descriptionEn: data.description_en,
          imgUrl: imgUrl,
          beerType: data.beer_type,
          abv: data.abv,
          ibu: data.ibu,
          madeIn: data.made_in,
          breweryName: data.brewery_name,
          beerTypeEn: data.beer_type_en,
          madeInEn: data.made_in_en,
          breweryNameEn: data.brewery_name_en,
          priority: data.priority,
        };
      });

      const fetchedItems = await Promise.all(fetchItemDataPromises);
      return fetchedItems;
    } catch (error) {
      console.error('Error fetching items:', error);
      throw error;
    }
  };
}
