import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { TodoPage } from '../../components/pages/Todo';
import { useGlobalState } from '../../hooks/global';
import { PageLoader } from '../../components/templates/PageLoader';
import { TopPage } from '../../components/pages/Top';
import { MugiPage } from '../../components/pages/Mugi';
import { BakuPage } from '../../components/pages/Baku';
import { MenuCategoryBakuPage } from '../../components/pages/MenuCategoryBaku';
import { MenuCategoryMugiPage } from '../../components/pages/MenuCategoryMugi';
import { CalendarPage } from '../../components/pages/Calendar';

const ProtectedRoutes = () => {
  return (
    <Routes>
      <Route path="/*" element={<Navigate to="/home" />} />
      <Route path="home" element={<TodoPage />} />
    </Routes>
  );
};

const UnprotectedRoutes = () => {
  return (
    <Routes>
      <Route path="/*" element={<Navigate to="/" />}></Route>
      <Route path="/" element={<TopPage />}></Route>
      <Route path="/baku/menu" element={<MenuCategoryBakuPage />} />
      <Route path="/mugi/menu" element={<MenuCategoryMugiPage />} />
      <Route path="/calendar" element={<CalendarPage />}></Route>
      <Route path="/mugi" element={<MugiPage />}></Route>
      <Route path="/baku" element={<BakuPage />}></Route>
    </Routes>
  );
};

const RootNavigator = (): React.ReactElement => {
  const {
    useCurrentUser: { currentUser },
  } = useGlobalState();

  return (
    <React.Suspense fallback={<PageLoader />}>
      <BrowserRouter>
        {!!currentUser ? <ProtectedRoutes /> : <UnprotectedRoutes />}
      </BrowserRouter>
    </React.Suspense>
  );
};

export default RootNavigator;
