import React, { useState, useEffect } from 'react';
import { useIsFetching, useQuery } from 'react-query';

import { Category, Subcategory } from '../../../domain/entities/category';

import { MenuCategoryBakuTemplate } from '../../templates/MenuCategoryBaku';
import { useLocation } from 'react-router';
import { useRestaurantHooks } from '../../../hooks/restaurant';
import { useCategoryHooks } from '../../../hooks/category';
import { useItemHooks } from '../../../hooks/item';
import { PageLoader } from '../../templates/PageLoader';

type Props = {};

const MenuCategoryBaku = ({}: Props): React.ReactElement => {
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [queryParams, setQueryParams] = useState<{
    page: number;
    pageSize: number;
  }>({ page: 1, pageSize: 5 });

  const [fetchedCategories, setFetchedCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<
    Category | undefined
  >(undefined);
  const [selectedSubCategory, setSelectedSubCategory] = useState<
    Subcategory | undefined
  >(undefined);

  const { useRestaurants } = useRestaurantHooks();
  const { fetchRestaurantByName } = useRestaurants();
  const { useCategories } = useCategoryHooks();
  const { fetchCategoriesByRestaurant } = useCategories();
  const { useItems } = useItemHooks();
  const {
    fetchItemsByCategoryOrSubcategory,
    fetchItemsByRestaurant,
  } = useItems();

  const isFetching = useIsFetching();

  const { data: restaurant } = useQuery(
    ['baku-restaurant'],
    async () => await fetchRestaurantByName('BAKU'),
    {
      refetchOnMount: false,
    },
  );

  const restaurantId = restaurant?.id;

  const { data: categories } = useQuery(
    ['baku-restaurant-categories-list', restaurantId],
    async () => await fetchCategoriesByRestaurant(restaurantId ?? ''),
    {
      enabled:
        !!restaurantId && !location.state && fetchedCategories.length === 0,
      refetchOnMount: true,
    },
  );

  const { data: restaurantItems } = useQuery(
    ['baku-menu-restaurant-items', restaurantId],
    async () => await fetchItemsByRestaurant(restaurantId ?? ''),
    {
      enabled: !!restaurantId && fetchedCategories.length === 0,
      refetchOnMount: true,
    },
  );

  const { data: items } = useQuery(
    ['baku-category-items-list', restaurantId, selectedCategory],
    async () =>
      await fetchItemsByCategoryOrSubcategory(selectedCategory?.id ?? ''),
    {
      enabled: !!restaurantId && !!selectedCategory,
      refetchOnMount: true,
    },
  );

  const handleLoadMoreItems = () => {
    // Note: Uncomment if pagination will be reimplemented
    // setQueryParams(({ page, pageSize }) => ({ page: page + 1, pageSize }));
  };

  const handleChangeCategory = (value?: Category) => {
    if (value?.id) window.sessionStorage.setItem('activeCategoryId', value.id);
    window.sessionStorage.removeItem('activeSubcategoryId');
    setSelectedCategory(value);
  };

  const handleChangeSubcategory = (value?: Subcategory) => {
    if (value?.id)
      window.sessionStorage.setItem('activeSubcategoryId', value.id);
    setSelectedSubCategory(value);
  };

  useEffect(() => {
    if (categories && categories.length > 0) {
      const filteredCategories = categories.filter(category =>
        restaurantItems?.some(item => item.categoryId === category.id),
      );

      const mappedCategories = filteredCategories.map(category => {
        if (category.subcategories.length > 0) {
          const subcategoriesWithItems = category.subcategories.filter(
            subcategory =>
              restaurantItems?.some(
                item => item.subcategoryId === subcategory.id,
              ),
          );
          return {
            ...category,
            subcategories: subcategoriesWithItems,
          };
        }
        return category;
      });
      setFetchedCategories(mappedCategories);

      const activeCategoryId = window.sessionStorage.getItem(
        'activeCategoryId',
      );
      const activeSubcategoryId = window.sessionStorage.getItem(
        'activeSubcategoryId',
      );

      if (activeCategoryId) {
        const selectedCat = mappedCategories.find(
          cat => cat.id === activeCategoryId,
        );
        setSelectedCategory(selectedCat);

        if (selectedCat && activeSubcategoryId) {
          if (
            selectedCat.subcategories &&
            selectedCat.subcategories.length > 0
          ) {
            const selectedSub = selectedCat.subcategories.find(
              sub => sub.id === activeSubcategoryId,
            );
            setSelectedSubCategory(selectedSub);
          }
        }
      } else {
        if (mappedCategories && mappedCategories.length > 0) {
          setSelectedCategory(mappedCategories[0]);

          if (
            mappedCategories[0].subcategories &&
            mappedCategories[0].subcategories.length > 0
          ) {
            setSelectedSubCategory(mappedCategories[0].subcategories[0]);
          }
        }
      }
    }
  }, [categories, restaurantItems]);

  useEffect(() => {
    const activeCategoryId = window.sessionStorage.getItem('activeCategoryId');
    const activeSubcategoryId = window.sessionStorage.getItem(
      'activeSubcategoryId',
    );

    if (activeCategoryId) {
      const newSelCateg = fetchedCategories.find(
        e => e.id === activeCategoryId,
      );
      setSelectedCategory(newSelCateg);

      if (newSelCateg?.subcategories && newSelCateg.subcategories.length > 0) {
        if (activeSubcategoryId) {
          const newSelSubcateg = newSelCateg.subcategories.find(
            e => e.id === activeSubcategoryId,
          );
          setSelectedSubCategory(newSelSubcateg);
        } else setSelectedSubCategory(newSelCateg.subcategories[0]);
      } else setSelectedCategory(fetchedCategories[0]);
      window.scrollTo(0, 0);
    }
  }, [location.state, fetchedCategories]);

  const [isPageLoading, setIsPageLoading] = useState(true);

  useEffect(() => {
    if ((items == undefined || items.length <= 0) && isFetching > 0) {
      setIsPageLoading(true);
    } else {
      setIsPageLoading(false);
    }
  }, [items]);

  if (isPageLoading) {
    return <PageLoader />;
  }

  return (
    <MenuCategoryBakuTemplate
      categories={fetchedCategories}
      items={items || []}
      selectedCategory={selectedCategory}
      selectedSubcategory={selectedSubCategory}
      onChangeCategory={handleChangeCategory}
      onChangeSubcategory={handleChangeSubcategory}
      onLoadMoreItems={handleLoadMoreItems}
    />
  );
};

export default MenuCategoryBaku;
