import ItemRepository from '../../ports/ItemRepository';
import {
  buildFetchItemsByCategoryOrSubcategory,
  fetchItemsByCategoryOrSubcategoryUseCase,
} from './fetch-items-by-category-or-subcategory';
import {
  buildFetchItemsByRestaurant,
  fetchItemsByRestaurantUseCase,
} from './fetch-items-by-restaurant';

export default (dependencies: {
  itemRepo: ItemRepository;
}): {
  fetchItemsByCategoryOrSubcategory: fetchItemsByCategoryOrSubcategoryUseCase;
  fetchItemsByRestaurant: fetchItemsByRestaurantUseCase;
} => {
  const { itemRepo } = dependencies;

  const fetchItemsByCategoryOrSubcategory = buildFetchItemsByCategoryOrSubcategory(
    { itemRepo },
  );

  const fetchItemsByRestaurant = buildFetchItemsByRestaurant({ itemRepo });

  return { fetchItemsByCategoryOrSubcategory, fetchItemsByRestaurant };
};
