import { createContext, useContext } from 'react';
import { Restaurant } from '../../domain/entities/restaurant';

export type RestaurantHooks = {
  useRestaurants: () => {
    fetchRestaurantByName: (name: string) => Promise<Restaurant>;
  };
};

export const RestaurantHooksContext = createContext<RestaurantHooks | null>(
  null,
);

export const useRestaurantHooks = (): RestaurantHooks => {
  const context = useContext(RestaurantHooksContext);
  if (!context) {
    throw new Error(`Context not instantiated`);
  }

  return context;
};
