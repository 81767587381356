const colors = {
  white: '#FFFFFF',
  black: '#000000',
  black2: '#232227',
  blackText: '#333333',
  redText: '#E20000',
  tabBg: '#d6d6d6',
  tabBgMugi: '#D6FFBA',
  tabBgMugi2: '#688A50',
  tabBgBaku: '#333333',
  tabSubBg: '#E8E8E8',
  tabSelector: '#707070',
  drawerBg: '#232227',
  yellow: '#FEF102',
  hoverBlack: '#333333',
  bgPrimary: '#D6FFBA',
  underlineBlack: '#717171',
  mugiDivider: '#4E5847',
};

const fonts = {
  primary: 'Hiragino Mincho ProN, W3',
  bold: 'Hiragino Mincho ProN, W6',
  notoSansJP: 'Noto Sans JP',
};

const breakpoints = {
  mobile: '(min-width: 320px) and (max-width: 576px)',
  mobileAndTablet: '(min-width: 320px) and (max-width: 922px)',
  tablet: '(min-width: 577px) and (max-width: 922px)',
  pc: '(min-width: 923px)',
  wideScreen: '(min-width: 1400px)',
};

export default {
  colors,
  fonts,
  breakpoints,
};
