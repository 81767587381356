import CategoryRepository from '../../ports/CategoryRepository';
import {
  buildFetchCategoriesByRestaurant,
  fetchCategoriesByRestaurantUseCase,
} from './fetch-categories-by-restaurant';

export default (dependencies: {
  categoryRepo: CategoryRepository;
}): {
  fetchCategoriesByRestaurant: fetchCategoriesByRestaurantUseCase;
} => {
  const { categoryRepo } = dependencies;

  const fetchCategoriesByRestaurant = buildFetchCategoriesByRestaurant({
    categoryRepo,
  });

  return { fetchCategoriesByRestaurant };
};
