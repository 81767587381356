import React from 'react';

type Props = {
  [key: string]: string | number;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="32"
      height="26"
      viewBox="0 0 32 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M10.0634 26.0009C22.1389 26.0009 28.7437 15.9965 28.7437 7.32058C28.7437 7.03646 28.7437 6.75358 28.7245 6.47196C30.0094 5.54252 31.1186 4.39183 32 3.07358C30.8018 3.60452 29.5306 3.95264 28.2291 4.10652C29.5994 3.28627 30.6256 1.99514 31.1155 0.475143C29.8268 1.23983 28.4167 1.77883 26.9466 2.06877C25.7052 0.748705 23.9714 -0.000732422 22.1595 -0.000732422C18.5544 -0.000732422 15.5879 2.96577 15.5879 6.57077C15.5879 7.07089 15.6451 7.56946 15.7581 8.05658C10.4819 7.79214 5.55931 5.29652 2.22719 1.19708C0.494437 4.17996 1.391 8.04558 4.25981 9.96121C3.21512 9.93027 2.19287 9.64839 1.28 9.13945V9.22264C1.28087 12.336 3.49544 15.0421 6.54719 15.6585C5.58069 15.9221 4.56638 15.9606 3.58275 15.7711C4.44069 18.439 6.91469 20.2779 9.7165 20.3305C7.39213 22.1572 4.51919 23.1495 1.56288 23.1465C1.0405 23.1455 0.518625 23.1139 0 23.0518C3.00213 24.9783 6.49625 26.0005 10.0634 25.9958"
        fill="#1DA1F2"
      />
    </svg>
  );
};

export default Component;
