import CalendarRepository from '../../ports/CalendarRepository';
import { buildFetchCalendars, fetchCalendarsUseCase } from './fetch-calendars';

export default (dependencies: {
  calendarRepo: CalendarRepository;
}): { fetchCalendars: fetchCalendarsUseCase } => {
  const { calendarRepo } = dependencies;

  const fetchCalendars = buildFetchCalendars({ calendarRepo });

  return { fetchCalendars };
};
