import { app } from '../../firebase';
import {
  Firestore,
  collection,
  query,
  where,
  getDocs,
  getFirestore,
  orderBy,
} from 'firebase/firestore';
import CategoryRepository from '../../usecases/ports/CategoryRepository';
import { Category, Subcategory } from '../../domain/entities/category';

export default class CategoryRepositoryImpl implements CategoryRepository {
  firestore: Firestore;
  collectionName: string;

  constructor() {
    this.firestore = getFirestore(app);
    this.collectionName = 'categories';
  }

  findCategoriesByRestaurant = async (
    restaurantId: string,
  ): Promise<Category[]> => {
    const collectionRef = collection(this.firestore, this.collectionName);
    const categoriesQuery = query(
      collectionRef,
      where('restaurant_id', '==', restaurantId),
      orderBy('priority', 'asc'),
    );

    try {
      const categoryDocs = await getDocs(categoriesQuery);

      const mappedCategoryDocs: Category[] = await Promise.all(
        categoryDocs.docs.map(async value => {
          const subcategoriesRef = collection(value.ref, 'subcategories');
          const subcategoriesDocs = await getDocs(
            query(subcategoriesRef, orderBy('priority', 'asc')),
          );

          const mappedSubcategories: Subcategory[] = subcategoriesDocs.docs.map(
            subcat => {
              return {
                id: subcat.id,
                name: subcat.get('name'),
                nameEn: subcat.get('name_en'),
                restaurantId: subcat.get('restaurant_id'),
                isForBeer: subcat.get('is_for_beer'),
                priority: subcat.get('priority'),
              };
            },
          );

          return {
            id: value.id,
            name: value.get('name'),
            nameEn: value.get('name_en'),
            restaurantId: value.get('restaurant_id'),
            isForBeer: value.get('is_for_beer'),
            priority: value.get('priority'),
            subcategories: mappedSubcategories,
            is_for_monitor: value.get('is_for_monitor') ?? false,
          };
        }),
      );
      return mappedCategoryDocs;
    } catch (error) {
      console.error('Error fetching categories:', error);
      throw error;
    }
  };
}
