import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import './index.css';
import RootNavigator from './navigators/root/RootNavigator';
import * as serviceWorker from './serviceWorker';
import Hooks from './hooks';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <QueryClientProvider
      client={
        new QueryClient({
          defaultOptions: {
            queries: {
              retry: 0,
              suspense: true,
              refetchOnMount: false,
              refetchOnWindowFocus: false,
            },
          },
        })
      }>
      <Hooks>
        <RootNavigator />
      </Hooks>
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
