import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import theme from '../../../constants/themes';
import { Top } from '../../templates/Top';
import { Box, Stack, Typography, Link } from '@mui/material';
import styled from '@mui/styled-engine';
import _MugiLogo from '../../../assets/images/mugi_logo.png';
import styledcomponent from 'styled-components';
import { useRestaurantHooks } from '../../../hooks/restaurant';
import { useCategoryHooks } from '../../../hooks/category';
import { useItemHooks } from '../../../hooks/item';

type Props = {};

const MenuContainer = styled(Box)`
  padding-top: 100px;
  background-color: ${theme.colors.bgPrimary};
  background-repeat: no-repeat;
  background-size: cover;

  @media ${theme.breakpoints.pc} {
  }
`;

const MenuWrapper = styled(Stack)`
  margin: 0 70px 58px;
  align-items: center;

  @media ${theme.breakpoints.mobile} {
    margin-bottom: 26px;
  }
`;

const MenuHeader = styled(Typography)`
  font-family: "${theme.fonts.primary}";
  font-size: 30px;
  color: black;
  padding: 34px 16px 34px 16px;

  @media ${theme.breakpoints.pc} {
    font-size: 32px;
    color: ${theme.colors.blackText};
    line-height: 48px;
  }
`;

const MenuText = styled(Link)`
  color: black;
  font-family: "${theme.fonts.primary}";
  font-size: 24px;
  text-decoration: none;
  cursor: pointer;
  text-align: center;

  @media ${theme.breakpoints.mobile} {
    font-size: 18px;
    line-height: 34px;
    font-weight: 300;
  }
`;

const Container = styled(Box)`
  padding: 32px 25px;
  background-color: ${theme.colors.drawerBg};
  background-repeat: no-repeat;
  background-size: cover;

  @media ${theme.breakpoints.pc} {
    padding-top: 72px;
    padding-bottom: 71px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const ContainerTitle = styled(Typography)`
  font-family: "${theme.fonts.primary}";
  font-size: 18px;
  padding-bottom: 4px;  
  line-height: 34px;
  text-align: center;
  text-decoration: none;
  color: ${theme.colors.white};

  position: relative;

  ::before {
    border-bottom: solid 2px #D6FFBA;
    position: absolute;
    content: '';
    bottom: 0;
    width: 50%;
    left: 25%;
  }

  @media ${theme.breakpoints.pc} {
    font-size: 24px;
    line-height: 36px;

    margin-bottom: 48px;


    ::before{
      width: 40%;
      left: 30%;
    }
  }
`;

const ContainerSubTitle = styled(Typography)`
  color: ${theme.colors.white};
  font-family: "${theme.fonts.primary}";
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  padding-top: 22px;
  padding-bottom: 32px;

  @media ${theme.breakpoints.pc} {
    font-size: 24px;
    line-height: 36px;
  }
`;

const MugiLogoWrapper = styledcomponent.div`
  height: 155px;
  width: 155px;
  border: 1px solid;
  padding: 0.2em;

  @media ${theme.breakpoints.pc} {
    width: 145px;
    height: 145px;
  }
`;

const _StyledMugi = styledcomponent.img`
  width: 100%;
  height: 100%;
`;

const DescriptionContainer = styledcomponent.div`
  width: 590px;

  @media ${theme.breakpoints.pc} {
    width: 872px;
    margin-bottom: 56px;
  }

  @media ${theme.breakpoints.mobileAndTablet} {
    width: 100%;
  }
`;

const AccessContainer = styledcomponent.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media ${theme.breakpoints.mobileAndTablet} {
  }
`;

const AccessTitleContainer = styledcomponent.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 39px;
`;

const AccessTitle = styled(Typography)`
  font-family: "${theme.fonts.primary}";
  color: ${theme.colors.white};
  font-size: 18px;
  line-height: 34px;

  @media ${theme.breakpoints.pc} {
    font-size: 24px;
    line-height: 36px;
  }
`;

const AccessDivider = styledcomponent.hr`
  width: 142px;
  border-color: ${theme.colors.bgPrimary};
  margin-top: 0;

  @media ${theme.breakpoints.mobileAndTablet} {
    width: 102px;
  }
`;

const AccessDescriptionContainer = styledcomponent.div`
  margin-top: 1em;
`;

const AccessDescription = styled(Typography)`
  font-family: "${theme.fonts.primary}";
  color: ${theme.colors.white};
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;

  @media ${theme.breakpoints.pc} {
    font-size: 24px;
    line-height: 36px;
  }
`;

const AccessMapContainer = styledcomponent.div`
  width: 100%;
`;

const Component = ({}: Props): React.ReactElement => {
  const navigate = useNavigate();
  const { useRestaurants } = useRestaurantHooks();
  const { fetchRestaurantByName } = useRestaurants();
  const { useCategories } = useCategoryHooks();
  const { fetchCategoriesByRestaurant } = useCategories();
  const { useItems } = useItemHooks();
  const { fetchItemsByRestaurant } = useItems();

  const { data: restaurant } = useQuery(
    ['mugi-top-restaurant'],
    async () => await fetchRestaurantByName('mugi'),
    {
      refetchOnMount: true,
    },
  );

  const restaurantId = restaurant?.id;

  const { data: categories } = useQuery(
    ['mugi-categories', restaurantId],
    async () => await fetchCategoriesByRestaurant(restaurantId ?? ''),
    {
      enabled: !!restaurantId,
      refetchOnMount: true,
    },
  );

  const { data: items } = useQuery(
    ['mugi-restaurant-items', restaurantId],
    async () => await fetchItemsByRestaurant(restaurantId ?? ''),
    {
      enabled: !!restaurantId,
      refetchOnMount: true,
    },
  );

  const filteredCategories = categories?.filter(category =>
    items?.some(item => item.categoryId === category.id),
  );

  const mappedCategories = filteredCategories?.map(category => {
    if (category.subcategories.length > 0) {
      const subcategoriesWithItems = category.subcategories.filter(
        subcategory =>
          items?.some(item => item.subcategoryId === subcategory.id),
      );
      return {
        ...category,
        subcategories: subcategoriesWithItems,
      };
    }
    return category;
  });

  const handleNavigateMenu = (data: { id: string; name: string }) => {
    window.sessionStorage.setItem('activeCategoryId', data.id);
    navigate('/mugi/menu');
  };

  return (
    <Top footerColorScheme="dark">
      <Stack>
        <MenuContainer>
          <MenuWrapper>
            <MugiLogoWrapper>
              <_StyledMugi src={_MugiLogo} />
            </MugiLogoWrapper>
            <MenuHeader>MENU</MenuHeader>
            <Stack
              direction={{ sm: 'column', md: 'column' }}
              alignItems={'center'}
              spacing={{ sm: 2, md: 2 }}>
              {mappedCategories &&
                mappedCategories.length > 0 &&
                mappedCategories.map(category => {
                  return (
                    <MenuText
                      key={category.id}
                      onClick={() => {
                        handleNavigateMenu({
                          id: category.id,
                          name: category.name,
                        });
                      }}>
                      {category.name}
                    </MenuText>
                  );
                })}

              {mappedCategories && mappedCategories.length > 0 && (
                <MenuText
                  onClick={() => {
                    window.sessionStorage.setItem('defaultLanguage', 'EN');
                    handleNavigateMenu({
                      id: mappedCategories[0].id,
                      name: mappedCategories[0].name,
                    });
                  }}>
                  English Menu
                </MenuText>
              )}
            </Stack>
          </MenuWrapper>
        </MenuContainer>
        <Container>
          <DescriptionContainer>
            <ContainerTitle>mugiについて</ContainerTitle>
            <ContainerSubTitle>
              国内・海外のブルワリーから店主が厳選したクラフトビールを全9タップお楽しみいただけます。
            </ContainerSubTitle>
          </DescriptionContainer>

          <AccessContainer>
            <AccessTitleContainer>
              <AccessTitle>アクセス</AccessTitle>
              <AccessDivider />
            </AccessTitleContainer>
            <AccessDescriptionContainer>
              <AccessDescription>広島市中区上幟町3-3</AccessDescription>
              <AccessDescription>サンガーデン上幟2F</AccessDescription>
            </AccessDescriptionContainer>
          </AccessContainer>
        </Container>
        <AccessMapContainer>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d823.0222559534186!2d132.4656301!3d34.3990831!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x355a998bcfe4e2c9%3A0x9b094906019405dc!2zbXVnaSAo44Kv44Op44OV44OI44OT44O844Or44OQ44O8KQ!5e0!3m2!1sen!2sph!4v1690268187569!5m2!1sen!2sph"
            width="100%"
            height="430"
            style={{ border: 0 }}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"></iframe>
        </AccessMapContainer>
      </Stack>
    </Top>
  );
};

export default Component;
