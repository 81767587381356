import { Calendar } from '../../../domain/entities/calendar';
import CalendarRepository from '../../ports/CalendarRepository';

export type fetchCalendarsUseCase = () => Promise<Calendar[]>;

export const buildFetchCalendars = (dependencies: {
  calendarRepo: CalendarRepository;
}): fetchCalendarsUseCase => {
  const { calendarRepo } = dependencies;

  const fetchCalendars: fetchCalendarsUseCase = async () =>
    calendarRepo.findAll();

  return fetchCalendars;
};
