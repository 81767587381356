import { createContext, useContext } from 'react';
import { Calendar } from '../../domain/entities/calendar';

export type CalendarHooks = {
  useFetchCalendars: () => {
    fetchCalendars: () => Promise<Calendar[]>;
  };
};

export const CalendarHooksContext = createContext<CalendarHooks | null>(null);

export const useCalendarHooks = (): CalendarHooks => {
  const context = useContext(CalendarHooksContext);
  if (!context) {
    throw new Error(`Context not instantiated`);
  }
  return context;
};
