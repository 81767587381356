import React from 'react';
import Tabs, { TabsProps } from '@mui/material/Tabs';
import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import theme from '../../../constants/themes';

export type Props = {
  selectedValue: string;
  colorScheme?: 'light' | 'dark';
  isSub?: boolean;
  children?: React.ReactNode;
};

const colorSchemes = {
  light: {
    backgroundColor: theme.colors.tabBgMugi,
  },
  dark: {
    backgroundColor: theme.colors.tabBgBaku,
  },
};

const Container = styled(Box, {
  shouldForwardProp: prop => prop !== '$colorscheme' && prop !== '$issub',
})<BoxProps & { $colorscheme: 'light' | 'dark'; $issub: boolean }>(
  ({ $colorscheme, $issub }) => ({
    [`@media ${theme.breakpoints.mobile}`]: {
      width: '100%',
      paddingTop: '10px',
      paddingBottom: '11px',
      backgroundColor: colorSchemes[$colorscheme].backgroundColor,
    },
    [`@media ${theme.breakpoints.pc}`]: {
      backgroundColor: $issub ? theme.colors.tabBgBaku : 'transparent',
      paddingTop: $issub ? '18px' : '30px',
    },
  }),
);

const CustomTabs = styled(Tabs)<TabsProps>(() => ({
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  '& .MuiTabs-indicatorSpan': {
    backgroundColor: theme.colors.tabSelector,
    height: '1px',
    width: '80%',
  },
  '& .MuiTab-root': {
    color: theme.colors.blackText,
    textTransform: 'none',
    fontFamily: `"${theme.fonts.primary}"`,
    fontSize: '18px',
    lineHeight: '31px',
    paddingTop: 0,
    paddingBottom: 0,
  },
  '& .MuiTab-root:first-of-type': {
    marginLeft: '16px',
  },
  '& .MuiTab-root:last-child': {
    marginRight: '16px',
  },
  '& .MuiButtonBase-root': {
    whiteSpace: 'nowrap',
  },
  [`@media ${theme.breakpoints.pc}`]: {
    '& .MuiTabs-flexContainer': {
      justifyContent: 'center',
    },
  },
}));

const Component = ({
  selectedValue,
  children,
  colorScheme = 'light',
  isSub = false,
}: Props): React.ReactElement => {
  return (
    <Container $colorscheme={colorScheme} $issub={isSub}>
      <CustomTabs
        value={selectedValue}
        variant="scrollable"
        scrollButtons={false}
        TabIndicatorProps={{
          children: <span className="MuiTabs-indicatorSpan" />,
        }}
        allowScrollButtonsMobile>
        {children}
      </CustomTabs>
    </Container>
  );
};

export default Component;
