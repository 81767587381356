import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import styled from '@emotion/styled';
import theme from '../../../constants/themes';
import { Element } from 'react-scroll';

type Props = {
  colorScheme?: 'light' | 'dark';
};

const Text = styled(Typography)`
  font-family: "${theme.fonts.primary}";
  color: ${theme.colors.blackText};
`;

const AccessWrapper = styled(Stack, {
  shouldForwardProp: prop => prop !== '$colorScheme',
})<{ $colorScheme: string }>`
  background-color: ${props =>
    props.$colorScheme === 'light' ? theme.colors.white : theme.colors.black2};
  align-items: center;

  p {
    color: ${prop =>
      prop.$colorScheme === 'light' ? theme.colors.black : theme.colors.white};
  }
`;

const AccessMapWrapper = styled(Box)`
  max-width: 100vw;
  width: 100%;

  @media ${theme.breakpoints.mobile} {
    height: 360px;
  }

  @media ${theme.breakpoints.pc} {
    height: 480px;
  }
`;

const AccessTitleWrapper = styled(Box)``;

const AccessTitle = styled(Text, {
  shouldForwardProp: prop => prop !== '$colorScheme',
})<{ $colorScheme: string }>`
  font-size: 18px;
  line-height: 34px;
  margin-bottom: 16px;
  position: relative;

  ::before {
    content: '';
    position: absolute;
    border-bottom: solid 2px
      ${props =>
        props.$colorScheme === 'light'
          ? theme.colors.underlineBlack
          : theme.colors.bgPrimary};
    bottom: 0;
    left: -10%;
    width: 130%;
  }

  @media ${theme.breakpoints.pc} {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 40px;

    ::before {
      left: -20%;
      width: 140%;
    }
  }
`;

const AccessText = styled(Text)`
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  margin-bottom: 41px;

  @media ${theme.breakpoints.pc} {
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
    margin-bottom: 71px;
  }
`;

const Component = ({ colorScheme = 'light' }: Props): React.ReactElement => {
  return (
    <>
      <Element name="map">
        <AccessWrapper $colorScheme={colorScheme}>
          <AccessTitleWrapper>
            <AccessTitle $colorScheme={colorScheme}>アクセス</AccessTitle>
          </AccessTitleWrapper>
          <AccessText>
            広島市中区上幟町3-3 <br /> サンガーデン上幟2F
          </AccessText>
        </AccessWrapper>

        <AccessMapWrapper>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d823.0222559534186!2d132.4656301!3d34.3990831!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x355a998bcfe4e2c9%3A0x9b094906019405dc!2zbXVnaSAo44Kv44Op44OV44OI44OT44O844Or44OQ44O8KQ!5e0!3m2!1sen!2sph!4v1690268187569!5m2!1sen!2sph"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"></iframe>
        </AccessMapWrapper>
      </Element>
    </>
  );
};

export default Component;
