import axios from 'axios';

import HttpAdapterAxiosImpl from '../interface/adapters/HttpAdapterAxiosImpl';

import AuthServiceRestImpl from '../interface/services/AuthServiceRestImpl';
import TodoRepositoryImpl from '../interface/repositories/TodoRepositoryImpl';
import CalendarRepositoryImpl from '../interface/repositories/CalendarRepositoryImpl';
import RestaurantRepositoryImpl from '../interface/repositories/RestaurantRepositoryImpl';
import CategoryRepositoryImpl from '../interface/repositories/CategoryRepositoryImpl';
import ItemRepositoryImpl from '../interface/repositories/ItemRepositoryImpl';

import authUseCases from '../usecases/modules/auth';
import todoUseCases from '../usecases/modules/todo';
import calendarUseCases from '../usecases/modules/calendar';
import restaurantUseCases from '../usecases/modules/restaurant';
import categoryUseCases from '../usecases/modules/category';
import itemUseCases from '../usecases/modules/item';
import urls from '../constants/urls';

const { todo: todoUrls, auth: authUrls } = urls;

const httpAdapter = new HttpAdapterAxiosImpl(axios);

const authService = new AuthServiceRestImpl(httpAdapter, authUrls);

const todoRepo = new TodoRepositoryImpl(httpAdapter, todoUrls);

const calendarRepo = new CalendarRepositoryImpl();

const restaurantRepo = new RestaurantRepositoryImpl();

const categoryRepo = new CategoryRepositoryImpl();

const itemRepo = new ItemRepositoryImpl();

const authInteractor = authUseCases({ authService });
const todoInteractor = todoUseCases({ todoRepo });
const calendarInteractor = calendarUseCases({ calendarRepo });
const restaurantInteractor = restaurantUseCases({ restaurantRepo });
const categoryInteractor = categoryUseCases({ categoryRepo });
const itemInteractor = itemUseCases({ itemRepo });

export const dependencies = {
  httpAdapter,
  todoInteractor,
  authInteractor,
  calendarInteractor,
  restaurantInteractor,
  categoryInteractor,
  itemInteractor,
};

export type Dependencies = typeof dependencies;
