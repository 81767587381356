import React, { useState } from 'react';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroller';

import theme from '../../../constants/themes';
import { Category, Subcategory } from '../../../domain/entities/category';
import { Item } from '../../../domain/entities/item';

import { Header } from '../../organisms/Header';
import { Tabs } from '../../molecules/Tabs';
import { Tab } from '../../atoms/Tab';
import { Footer } from '../../organisms/Footer';
import { getLabel } from '../../../util/strings';

const HeaderWrapper = styled.div`
  background-color: ${theme.colors.tabBgBaku};
  padding-top: 60px;
`;

const TabsMobile = styled.div`
  @media ${theme.breakpoints.mobile} {
    display: block;
  }

  @media ${theme.breakpoints.tablet} {
    display: none;
  }

  @media ${theme.breakpoints.pc} {
    display: none;
  }
`;

const TabsPC = styled.div`
  @media ${theme.breakpoints.mobile} {
    display: none;
  }

  @media ${theme.breakpoints.pc} {
    display: block;
  }
`;

const EmptyContainer = styled.div`
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "${theme.fonts.primary}";

  @media ${theme.breakpoints.mobile} {
    font-size: 18px;
    line-height: 34px;
  }

  @media ${theme.breakpoints.pc} {
    font-size: 24px;
    line-height: 36px;
  }
`;

const ContentContainer = styled.div`
  margin: 32px 24px 73px;

  @media ${theme.breakpoints.pc} {
    margin: 56px 151px calc(77px - 10em) 151px;
  }

  @media ${theme.breakpoints.wideScreen} {
    margin: 79px 137px 101px 151px;
    min-height: 50vh;
  }
`;

const SubCategoryTitle = styled.div`
  font-family: "${theme.fonts.primary}";
  font-size: 32px;
  font-weight: 600;
  color: ${theme.colors.black};

  @media ${theme.breakpoints.mobile} {
    &:not(:first-of-type) {
      margin-top: 58px;
    }
  }

  @media ${theme.breakpoints.pc} {
    font-size: 40px;
    margin-left: 16px;
  }
`;

const MenuItemsContainer = styled(InfiniteScroll)`
  @media ${theme.breakpoints.pc} {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const MenuItem = styled.div`
  border-top: 1px solid ${theme.colors.blackText};
  min-height: 96px;

  @media ${theme.breakpoints.mobile} {
    &:not(:first-of-type) {
      margin-top: 46px;
    }
  }

  @media ${theme.breakpoints.pc} {
    width: calc(25% - 32px);
    margin: 8px 16px 32px;
  }
`;

const ItemDetailsRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const ItemImageContainer = styled.div`
  width: 80px;
`;

const ItemImageWrapper = styled.img`
  width: 100%;
  margin-top: 16px;
`;

const ItemDetailsContainer = styled.div`
  width: 100%;
`;

const ItemNameWrapper = styled.div`
  font-family: "${theme.fonts.primary}";
  font-size: 24px;
  font-weight: 600;
  color: ${theme.colors.black};
  margin-top: 8px;
`;

const ItemDetails1Wrapper = styled.div`
  font-family: "${theme.fonts.primary}";
  font-size: 16px;
  font-weight: 300;
  color: ${theme.colors.black};
  margin-top: 8px;
  white-space: pre-line;
  line-height: 2.5;

  @media ${theme.breakpoints.pc} {
    font-size: 18px;
  }
`;

const ItemDetails2Wrapper = styled.div`
  width: 100%;
  font-family: "${theme.fonts.primary}";
  font-size: 16px;
  font-weight: 300;
  color: ${theme.colors.black};
  white-space: pre-line;
  word-wrap: break-word;
  line-height: 2.5;

  @media ${theme.breakpoints.pc} {
    font-size: 18px;
  }
`;

const RenderItems = ({
  currentLang,
  categoryLabel,
  categoryEngLabel,
  onLoadMoreItems,
  items,
}: {
  currentLang: string;
  categoryLabel: string;
  categoryEngLabel?: string;
  onLoadMoreItems: () => void;
  items: Item[];
}) => {
  return (
    <>
      <SubCategoryTitle>
        {getLabel(currentLang, categoryLabel, categoryEngLabel)}
      </SubCategoryTitle>
      <MenuItemsContainer
        pageStart={1}
        loadMore={onLoadMoreItems}
        hasMore={false}>
        {items?.map(value => (
          <MenuItem key={value.id}>
            <ItemDetailsRow>
              <ItemDetailsContainer>
                <ItemNameWrapper>
                  {getLabel(currentLang, value.name, value.nameEn)}
                </ItemNameWrapper>
                <ItemDetails1Wrapper>
                  {value.variations.length > 0
                    ? value.variations.length > 1
                      ? value.variations.map(({ name, nameEn, price }) => (
                          <>{`${getLabel(
                            currentLang,
                            name,
                            nameEn,
                          )}: ¥${price.toLocaleString()}\n`}</>
                        ))
                      : `¥${value.variations[0].price.toLocaleString()}\n`
                    : null}
                </ItemDetails1Wrapper>
              </ItemDetailsContainer>
              {value.imgUrl && (
                <ItemImageContainer>
                  <ItemImageWrapper src={value.imgUrl} />
                </ItemImageContainer>
              )}
            </ItemDetailsRow>
            <ItemDetailsRow>
              <ItemDetails2Wrapper>
                {(value.description || value.descriptionEn) &&
                  getLabel(currentLang, value.description, value.descriptionEn)}
              </ItemDetails2Wrapper>
            </ItemDetailsRow>
          </MenuItem>
        ))}
      </MenuItemsContainer>
    </>
  );
};

type Props = {
  categories: Category[];
  items: Item[];
  selectedCategory?: Category;
  selectedSubcategory?: Subcategory;
  onChangeCategory: (value?: Category) => void;
  onChangeSubcategory: (value?: Subcategory) => void;
  onLoadMoreItems: () => void;
};

const MenuCategoryBaku = ({
  categories,
  items,
  selectedCategory,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  selectedSubcategory,
  onChangeCategory,
  onChangeSubcategory,
  onLoadMoreItems,
}: Props): React.ReactElement => {
  const categoryList = categories.map(
    ({ id, name, nameEn, subcategories }) => ({
      value: id,
      label: name,
      engLabel: nameEn,
      subItems: subcategories.map(({ id, name, nameEn }) => ({
        value: id,
        label: name,
        engLabel: nameEn,
      })),
    }),
  );

  const onSelectCategory = (tabValue: string) => () => {
    const newSelCateg = categories.find(e => e.id === tabValue);
    onChangeCategory(newSelCateg);
    if (newSelCateg?.subcategories.length === 0) onChangeSubcategory(undefined);
  };

  const [currentLang, setCurrentLang] = useState(
    window.sessionStorage.getItem('defaultLanguage') ?? 'JP',
  );

  const handleLanguageSwitch = (value: string) => {
    window.sessionStorage.setItem('defaultLanguage', value);
    setCurrentLang(value);
  };

  return (
    <>
      <HeaderWrapper>
        <Header
          translationToggle
          onSwitchLanguage={handleLanguageSwitch}
          defaultLanguage={currentLang}
        />
        <TabsMobile>
          <Tabs colorScheme="dark" selectedValue={selectedCategory?.id || ''}>
            {categoryList.map(({ value, label, engLabel }, index) => (
              <Tab
                key={index}
                value={value}
                label={getLabel(currentLang, label, engLabel)}
                colorScheme="dark"
                isActive={selectedCategory?.id === value}
                onClick={onSelectCategory(value)}
              />
            ))}
          </Tabs>
        </TabsMobile>
        <TabsPC>
          <Tabs colorScheme="dark" selectedValue={selectedCategory?.id || ''}>
            {categoryList.map(({ value, label, engLabel }, index) => (
              <Tab
                key={index}
                value={value}
                label={getLabel(currentLang, label, engLabel)}
                colorScheme="dark"
                isActive={selectedCategory?.id === value}
                onClick={onSelectCategory(value)}
              />
            ))}
          </Tabs>
        </TabsPC>
      </HeaderWrapper>
      {categoryList.length <= 0 && items.length <= 0 ? (
        <EmptyContainer>商品が登録されていません。</EmptyContainer>
      ) : (
        <ContentContainer>
          {categoryList.map(cat => {
            if (selectedCategory && selectedCategory.id === cat.value) {
              if (cat.subItems && cat.subItems.length > 0) {
                const itemsInCategoryOnly = items.filter(e => !e.subcategoryId);

                return (
                  <>
                    {itemsInCategoryOnly.length > 0 && (
                      <RenderItems
                        currentLang={currentLang}
                        categoryLabel={cat.label}
                        categoryEngLabel={cat.engLabel}
                        onLoadMoreItems={onLoadMoreItems}
                        items={itemsInCategoryOnly}
                      />
                    )}
                    {cat.subItems.map(subItem => {
                      const subcatItems = items.filter(
                        item => item.subcategoryId === subItem.value,
                      );

                      return (
                        <RenderItems
                          key={subItem.value}
                          currentLang={currentLang}
                          categoryLabel={subItem.label}
                          categoryEngLabel={subItem.engLabel}
                          onLoadMoreItems={onLoadMoreItems}
                          items={subcatItems}
                        />
                      );
                    })}
                  </>
                );
              } else {
                return (
                  <RenderItems
                    key={cat.value}
                    currentLang={currentLang}
                    categoryLabel={cat.label}
                    categoryEngLabel={cat.engLabel}
                    onLoadMoreItems={onLoadMoreItems}
                    items={items}
                  />
                );
              }
            }
          })}
        </ContentContainer>
      )}
      <Footer />
    </>
  );
};

export default MenuCategoryBaku;
