import { Category } from '../../../domain/entities/category';
import CategoryRepository from '../../ports/CategoryRepository';

export type fetchCategoriesByRestaurantUseCase = (
  restaurantId: string,
) => Promise<Category[]>;

export const buildFetchCategoriesByRestaurant = (dependencies: {
  categoryRepo: CategoryRepository;
}): fetchCategoriesByRestaurantUseCase => {
  const { categoryRepo } = dependencies;

  const fetchCategoriesByRestaurant: fetchCategoriesByRestaurantUseCase = async (
    restaurantId: string,
  ) => await categoryRepo.findCategoriesByRestaurant(restaurantId);

  return fetchCategoriesByRestaurant;
};
