import { app } from '../../firebase';
import {
  Firestore,
  collection,
  query,
  where,
  limit,
  getDocs,
  getFirestore,
} from 'firebase/firestore';

import RestaurantRepository from '../../usecases/ports/RestaurantRepository';
import { Restaurant } from '../../domain/entities/restaurant';

export default class RestaurantRepositoryImpl implements RestaurantRepository {
  firestore: Firestore;
  collectionName: string;

  constructor() {
    this.firestore = getFirestore(app);
    this.collectionName = 'restaurants';
  }

  findRestaurantByName = async (name: string): Promise<Restaurant> => {
    const collectionRef = collection(this.firestore, this.collectionName);
    const restaurantQuery = query(
      collectionRef,
      where('name', '==', name),
      limit(1),
    );

    try {
      const restaurantDocs = await getDocs(restaurantQuery);
      const restaurantDoc = restaurantDocs.docs[0];

      return {
        id: restaurantDoc.id,
        name: restaurantDoc.get('name'),
      };
    } catch (error) {
      console.error('Error fetching restaurant:', error);
      throw error;
    }
  };
}
