import { Item } from '../../../domain/entities/item';
import ItemRepository from '../../ports/ItemRepository';

export type fetchItemsByRestaurantUseCase = (
  restaurantId: string,
) => Promise<Item[]>;

export const buildFetchItemsByRestaurant = (dependencies: {
  itemRepo: ItemRepository;
}): fetchItemsByRestaurantUseCase => {
  const { itemRepo } = dependencies;

  const fetchItemsByRestaurant: fetchItemsByRestaurantUseCase = async (
    restaurantId: string,
  ) => await itemRepo.fetchAllItemsOfRestaurant(restaurantId);

  return fetchItemsByRestaurant;
};
