import { initializeApp, FirebaseApp } from 'firebase/app';
import { initializeFirestore } from 'firebase/firestore';

import config from './config/firebaseConfig';

let app: FirebaseApp;

if (config) {
  app = initializeApp(config);
  initializeFirestore(app, {
    experimentalForceLongPolling: true,
  });
} else {
  console.warn('FIREBASE_CONFIG が未設定です');
}

export { app };
