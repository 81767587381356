import { useCallback } from 'react';
import { RestaurantHooks } from '.';
import { useDependencies } from '..';
import { Restaurant } from '../../domain/entities/restaurant';

export const useRestaurants: RestaurantHooks['useRestaurants'] = (): {
  fetchRestaurantByName: (name: string) => Promise<Restaurant>;
} => {
  const { restaurantInteractor } = useDependencies();

  const fetchRestaurantByName = useCallback(async (name: string) => {
    const restaurant = await restaurantInteractor.fetchRestaurantByName(name);

    return restaurant;
  }, []);

  return { fetchRestaurantByName };
};
