import { Item } from '../../../domain/entities/item';
import ItemRepository from '../../ports/ItemRepository';

export type fetchItemsByCategoryOrSubcategoryUseCase = (
  categoryId: string,
  subcategoryId?: string,
) => Promise<Item[]>;

export const buildFetchItemsByCategoryOrSubcategory = (dependencies: {
  itemRepo: ItemRepository;
}): fetchItemsByCategoryOrSubcategoryUseCase => {
  const { itemRepo } = dependencies;

  const fetchItemsByCategoryOrSubcategory: fetchItemsByCategoryOrSubcategoryUseCase = async (
    categoryId: string,
    subcategoryId?: string,
  ) =>
    await itemRepo.fetchItemsByCategoryOrSubcategory(categoryId, subcategoryId);

  return fetchItemsByCategoryOrSubcategory;
};
