import { useCallback } from 'react';
import { CalendarHooks } from '.';
import { useDependencies } from '..';
import { Calendar } from '../../domain/entities/calendar';

export const useFetchCalendars: CalendarHooks['useFetchCalendars'] = (): {
  fetchCalendars: () => Promise<Calendar[]>;
} => {
  const { calendarInteractor } = useDependencies();
  const fetchCalendars = useCallback(async () => {
    const calendars = await calendarInteractor.fetchCalendars();

    return calendars;
  }, []);

  return { fetchCalendars };
};
