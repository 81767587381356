import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import Image from 'mui-image';
import styled from '@emotion/styled';
import theme from '../../../constants/themes';
import BakuLogo from '../../../assets/images/BAKU_logo.png';
import MugiLogo from '../../../assets/images/mugi_logo.png';
import { Map } from '../../molecules/Map';
import { Header } from '../../organisms/Header';
import { Footer } from '../../organisms/Footer';
import { Calendar } from '../../../domain/entities/calendar';

const Text = styled(Typography)`
  font-family: "${theme.fonts.primary}";
  color: ${theme.colors.blackText};
`;

const CalendarHeader = styled(Text)`
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  text-align: center;
  padding-bottom: 28px;
  padding-top: 86px;

  @media ${theme.breakpoints.pc} {
    line-height: 60px;
    font-size: 40px;
    padding-top: 188px;
    padding-bottom: 48px;
  }
`;

const CalendarContainer = styled(Box)`
  display: flex;
  flex-direction: column;

  @media ${theme.breakpoints.pc} {
    flex-direction: row;
  }
`;

const CalendarWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${theme.breakpoints.pc} {
    min-height: 50vh;
    max-width: 50vw;
    width: 100%;
  }
`;

const LogoBox = styled(Box, {
  shouldForwardProp: prop => prop !== '$borderColor',
})<{ $borderColor?: string }>`
  margin-top: 46px;
  padding: 5px;
  border: 1px solid ${props => props.$borderColor ?? 'black'};

  width: 145px;
  height: 145px;

  img {
    width: 100%;
  }
`;

const CalendarImageWrapper = styled(Box)`
  margin: 40px 14px 46px 14px;
  padding-left: 14px;
  padding-right: 14px;

  img {
    min-height: 403px;
  }

  @media ${theme.breakpoints.pc} {
    max-width: 497px;
    margin: 89px auto 98px;
  }
`;

const MapContainer = styled(Box)`
  margin-top: 50px;

  @media ${theme.breakpoints.pc} {
    margin-top: 98px;
  }
`;

type Props = {
  footerColorScheme?: 'light' | 'dark';
  calendars: Calendar[];
  mugiCalendar?: Calendar;
  bakuCalendar?: Calendar;
  isLoading?: boolean;
  isSuccess?: boolean;
};

const Component = ({
  footerColorScheme = 'light',
  calendars,
  mugiCalendar,
  bakuCalendar,
  isLoading,
  isSuccess,
}: Props): React.ReactElement => {
  const [mugiImage, setMugiImage] = useState('');
  const [bakuImage, setBakuImage] = useState('');

  useEffect(() => {
    if (!isLoading && calendars.length > 0) {
      setMugiImage(mugiCalendar?.img_url ?? '');
      setBakuImage(bakuCalendar?.img_url ?? '');
    }
  }, [isLoading, calendars]);

  return (
    <>
      <Header onSwitchLanguage={() => {}} />
      <CalendarHeader>営業カレンダー</CalendarHeader>

      <CalendarContainer>
        <CalendarWrapper bgcolor={theme.colors.bgPrimary}>
          <LogoBox borderColor="black">
            <img src={MugiLogo} />
          </LogoBox>
          <CalendarImageWrapper>
            {isSuccess && mugiImage != '' && (
              <Image fit="contain" src={mugiImage} duration={0} showLoading />
            )}
          </CalendarImageWrapper>
        </CalendarWrapper>

        <CalendarWrapper bgcolor={theme.colors.blackText}>
          <LogoBox $borderColor="white">
            <img src={BakuLogo} />
          </LogoBox>
          <CalendarImageWrapper>
            {isSuccess && bakuImage != '' && (
              <Image fit="contain" src={bakuImage} duration={0} showLoading />
            )}{' '}
          </CalendarImageWrapper>
        </CalendarWrapper>
      </CalendarContainer>

      <MapContainer>
        <Map />
      </MapContainer>
      <Footer colorScheme={footerColorScheme} />
    </>
  );
};

export default Component;
