import React, { PropsWithChildren } from 'react';
import Box from '@mui/material/Box';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import theme from '../../../constants/themes';
import { styled } from '@mui/material/styles';
import { IconButton } from '@mui/material';

type Props = PropsWithChildren<{
  open: boolean;
  anchor?: DrawerProps['anchor'];
  onClick: () => void;
}>;

const Wrapper = styled(Box)`
  width: 100dvw;
  height: 100dvh;
  background-color: ${theme.colors.drawerBg};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${theme.breakpoints.mobile} {
    padding-top: 125px;
    justify-content: normal;
  }
`;

const CloseButtonContainer = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
`;

const CloseButton = styled(IconButton)`
  margin: 8px 16px;

  @media ${theme.breakpoints.pc} {
    margin: 40px;
  }
`;

const MenuItemContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Component = ({
  open,
  anchor = 'top',
  onClick,
  children,
}: Props): React.ReactElement => {
  return (
    <Drawer anchor={anchor} open={open}>
      <CloseButtonContainer>
        <CloseButton onClick={onClick}>
          <CloseIcon fontSize="large" sx={{ color: 'white' }} />
        </CloseButton>
      </CloseButtonContainer>
      <Wrapper>
        <MenuItemContainer>{children}</MenuItemContainer>
      </Wrapper>
    </Drawer>
  );
};

export default Component;
