import React from 'react';
import theme from '../../../constants/themes';
import { Box, Typography, Stack } from '@mui/material';
import styled from '@emotion/styled';
import { Twitter, Instagram } from '../../atoms/Icons';
import BakuLogo from '../../../assets/images/BAKU_logo.png';
import MugiLogo from '../../../assets/images/mugi_logo.png';
import { Link } from 'react-router-dom';

type Props = {
  colorScheme?: 'light' | 'dark';
};

const Text = styled(Typography, {
  shouldForwardProp: prop => prop !== '$colorScheme',
})<{ $colorScheme?: string }>`
  font-family: "${theme.fonts.primary}";
  color: ${props =>
    props.$colorScheme === 'light' ? theme.colors.blackText : 'white'};
`;

const FooterContainer = styled(Box)``;

// const SchedContainer = styled(Box, {
//   shouldForwardProp: prop => prop !== '$colorScheme',
// })<{ $colorScheme?: string }>`
//   background-color: ${props =>
//     props.$colorScheme === 'light' ? 'white' : '#232227'};
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   padding-top: 42px;
//   padding-bottom: 30px;

//   @media ${theme.breakpoints.pc} {
//     padding-top: 10em;
//     padding-bottom: 5em;
//   }
// `;

// const SchedBody = styled(Box)`
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   align-items: center;

//   @media ${theme.breakpoints.pc} {
//     flex-direction: row;
//     justify-content: center;
//   }
// `;

// const SchedWrapper = styled(Box)`
//   padding-bottom: 24px;

//   @media ${theme.breakpoints.mobile} {
//     &:last-of-type {
//       padding-bottom: 29px;
//     }
//   }

//   @media ${theme.breakpoints.pc} {
//     padding-bottom: 60px;
//     padding-left: 60px;
//     padding-right: 60px;
//   }
// `;

// const SchedTitle = styled(Text, {
//   shouldForwardProp: prop => prop !== '$colorScheme',
// })<{ $colorScheme: string }>`
//   font-size: 18px;
//   line-height: 34px;
//   margin-bottom: 16px;
//   position: relative;
//   display: inline-block;

//   ::before {
//     content: '';
//     position: absolute;
//     width: 90%;
//     height: 2px;
//     bottom: 0;
//     left: 5%;
//     border-bottom: solid 2px
//       ${prop =>
//         prop.$colorScheme === 'light'
//           ? theme.colors.underlineBlack
//           : theme.colors.bgPrimary};
//   }

//   @media ${theme.breakpoints.mobile} {
//     ::before {
//       width: 120%;
//       left: -10%;
//     }
//   }

//   @media ${theme.breakpoints.pc} {
//     font-size: 24px;
//     line-height: 36px;
//     margin-bottom: 60px;
//   }
// `;

// const SchedHeading = styled(Text)`
//   text-align: left;
//   font-size: 24px;
//   font-weight: 600;
//   line-height: 36px;

//   @media ${theme.breakpoints.pc} {
//     font-size: 32px;
//     line-height: 48px;
//     margin-bottom: 11px;
//   }
// `;

// const SchedText = styled(Text)`
//   font-size: 16px;
//   line-height: 28px;

//   @media ${theme.breakpoints.pc} {
//     font-size: 21px;
//     line-height: 31.5px;
//   }
// `;

// const SchedButton = styled(Button, {
//   shouldForwardProp: prop => prop !== '$colorScheme',
// })<{ $colorScheme?: string }>`
//   font-family: "${theme.fonts.primary}";
//   font-size: 18px;
//   font-weight: 600;
//   line-height: 27px;
//   padding: 4px 16px 4px 16px;
//   color: ${props =>
//     props.$colorScheme === 'light' ? theme.colors.blackText : 'white'};
//   border: 1px solid
//     ${props =>
//       props.$colorScheme === 'light' ? theme.colors.blackText : 'white'};
//   border-radius: 0px;

//   @media ${theme.breakpoints.pc} {
//     font-size: 24px;
//     line-height: 36px;
//     padding: 9px 24px;
//   }
// `;

const SocialContainer = styled(Stack)`
  background-color: black;
  padding-top: 48px;

  @media ${theme.breakpoints.pc} {
    padding-top: 80px;
  }
`;

const SocialWrapper = styled(Stack)`
  align-items: center;
  justify-content: center;
  padding-bottom: 46px;

  &:last-of-type {
    padding-bottom: 34px;
  }
`;

const SocialImageWrapper = styled(Box)`
  border: solid 1px white;
  width: 130px;
  height: 130px;
  padding: 5px;

  img {
    width: 100%;
    height: auto;
  }
`;

const SocialText = styled(Text)`
  color: white;
  font-size: 16px;
  line-height: 28px;
`;

const Component = ({ colorScheme = 'light' }: Props): React.ReactElement => {
  return (
    <FooterContainer>
      <SocialContainer>
        <SocialWrapper direction="row" spacing="40px">
          <SocialImageWrapper>
            <Link to="/mugi">
              <img src={MugiLogo} />
            </Link>
          </SocialImageWrapper>
          <SocialImageWrapper>
            <Link to="/baku">
              <img src={BakuLogo} />
            </Link>
          </SocialImageWrapper>
        </SocialWrapper>
        <SocialWrapper direction="row" spacing="40px">
          <a
            target="_blank"
            href="https://twitter.com/mugi_BAKU_2023"
            rel="noreferrer">
            <Twitter />
          </a>
          <a
            target="_blank"
            href="https://www.instagram.com/mugi_baku_2023/"
            rel="noreferrer">
            <Instagram />
          </a>
        </SocialWrapper>
        <SocialWrapper>
          <SocialText $colorScheme={colorScheme}>
            広島市中区上幟町3-3 サンガーデン上幟2F
          </SocialText>
        </SocialWrapper>
      </SocialContainer>
    </FooterContainer>
  );
};

export default Component;
