import React, { PropsWithChildren } from 'react';
import { Header } from '../../organisms/Header';
import { Footer } from '../../organisms/Footer';

type Props = PropsWithChildren<{
  footerColorScheme?: 'light' | 'dark';
}>;

const Component = ({
  footerColorScheme = 'light',
  children,
}: Props): React.ReactElement => {
  return (
    <>
      <Header onSwitchLanguage={() => {}} />
      {children}
      <Footer colorScheme={footerColorScheme} />
    </>
  );
};

export default Component;
