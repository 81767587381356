import { app } from '../../firebase';
import {
  Firestore,
  collection,
  getDocs,
  getFirestore,
} from 'firebase/firestore';
import {
  FirebaseStorage,
  getDownloadURL,
  getStorage,
  ref,
} from 'firebase/storage';

import CalendarRepository from '../../usecases/ports/CalendarRepository';
import { Calendar } from '../../domain/entities/calendar';

export default class CalendarRepositoryImpl implements CalendarRepository {
  firestore: Firestore;
  firebaseStorage: FirebaseStorage;
  collectionName: string;
  storageBasePath: string;

  constructor() {
    this.firestore = getFirestore(app);
    this.firebaseStorage = getStorage(app);
    this.collectionName = 'calendar';
    this.storageBasePath = 'calendar-images';
  }

  findAll = async (): Promise<Calendar[]> => {
    const collectionRef = collection(this.firestore, this.collectionName);

    const calendars: Calendar[] = [];

    await getDocs(collectionRef)
      .then(async snapshot => {
        if (!snapshot.empty) {
          await snapshot.docs.map(async doc => {
            let downloadUrl = '';

            if (doc.get('img_url')) {
              const storageRef = ref(this.firebaseStorage, doc.get('img_url'));
              downloadUrl = await getDownloadURL(storageRef);
            }

            calendars.push({
              id: doc.id,
              img_url: downloadUrl,
              restaurant_id: doc.get('restaurant_id'),
            });
          });
        }
      })
      .catch(error => console.log('Error fetching calendars:', error));

    return calendars;
  };
}
