import React from 'react';
import { Menu } from '../../atoms/Menu';
import { LanguageSwitcher } from '../../atoms/LanguageSwitcher';
import { Drawer } from '../../molecules/Drawer';
import { AppBar, Box, Link, Stack, Toolbar } from '@mui/material';
import styled from '@emotion/styled';
import theme from '../../../constants/themes';

type Props = {
  translationToggle?: boolean;
  isMugi?: boolean;
  defaultLanguage?: string;
  onSwitchLanguage: (value: 'JP' | 'EN') => void;
};

const CustomAppBar = styled(AppBar)`
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  z-index: 999;
  position: absolute;
`;

const StyledToolBar = styled(Toolbar)`
  justify-content: flex-end;

  @media ${theme.breakpoints.pc} {
    padding-top: 10px;
  }
`;

const MenuWrapper = styled(Box)`
  background-color: #232227;
  border-radius: 4px;
  margin-left: 32px;
`;

const DrawerItem = styled(Link)`
  color: white;
  font-family: "${theme.fonts.primary}";
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  text-decoration: none;

  @media ${theme.breakpoints.pc} {
  line-height: 48px;
  font-size: 32px;
  }
`;

const Component = ({
  translationToggle = false,
  isMugi = false,
  onSwitchLanguage,
  defaultLanguage,
}: Props): React.ReactElement => {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = () => setOpen(!open);

  const handleLanguageSwitch = (value: 'JP' | 'EN') => {
    onSwitchLanguage(value);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <CustomAppBar>
        <StyledToolBar>
          {translationToggle && (
            <LanguageSwitcher
              defaultLanguage={defaultLanguage ?? 'JP'}
              onSwitch={handleLanguageSwitch}
              isMugi={isMugi}
            />
          )}
          <MenuWrapper>
            <Menu onClick={toggleDrawer} />
          </MenuWrapper>
          <Drawer onClick={toggleDrawer} open={open}>
            <Stack spacing={{ xs: '16px', md: '24px' }}>
              <DrawerItem href="/">ホーム</DrawerItem>
              <DrawerItem>メニュー</DrawerItem>
              <DrawerItem paddingLeft="25%" href="/mugi">
                mugi
              </DrawerItem>
              <DrawerItem paddingLeft="25%" href="/baku">
                BAKU
              </DrawerItem>
              <DrawerItem onClick={toggleDrawer} href="/#map">
                アクセス
              </DrawerItem>
              <DrawerItem href="/calendar">営業日</DrawerItem>
            </Stack>
          </Drawer>
        </StyledToolBar>
      </CustomAppBar>
    </Box>
  );
};

export default Component;
