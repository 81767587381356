export const getLabel = (
  lang: string,
  japValue: string,
  engValue?: string,
): string => {
  if (lang === 'JP') {
    return japValue;
  } else {
    if (engValue && engValue.length > 0) {
      return engValue;
    }

    return japValue;
  }
};
