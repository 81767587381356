import { createContext, useContext } from 'react';
import { Category } from '../../domain/entities/category';

export type CategoryHooks = {
  useCategories: () => {
    fetchCategoriesByRestaurant: (restaurantId: string) => Promise<Category[]>;
  };
};

export const CategoryHooksContext = createContext<CategoryHooks | null>(null);

export const useCategoryHooks = (): CategoryHooks => {
  const context = useContext(CategoryHooksContext);
  if (!context) {
    throw new Error(`Context not instantiated`);
  }

  return context;
};
