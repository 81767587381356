import React from 'react';
import IconButton from '@mui/material/IconButton';
import { Menu } from '../../atoms/Icons';
import styled from '@emotion/styled';

type Props = {
  color?: 'white' | 'black';
  onClick?: () => void;
};

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: prop => prop !== '$color',
})<{ $color?: string }>`
  color: white;
  padding: 15px;
`;

const Component = ({ color = 'white', onClick }: Props): React.ReactElement => {
  return (
    <StyledIconButton $color={color} aria-label="menu" onClick={onClick}>
      <Menu color={color} />
    </StyledIconButton>
  );
};

export default Component;
