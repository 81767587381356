import RestaurantRepository from '../../ports/RestaurantRepository';
import {
  buildFetchRestaurantByName,
  fetchRestaurantByNameUseCase,
} from './fetch-restaurant-by-name';

export default (dependencies: {
  restaurantRepo: RestaurantRepository;
}): {
  fetchRestaurantByName: fetchRestaurantByNameUseCase;
} => {
  const { restaurantRepo } = dependencies;

  const fetchRestaurantByName = buildFetchRestaurantByName({ restaurantRepo });

  return { fetchRestaurantByName };
};
