import React from 'react';

type Props = {
  color?: string;
};

const Component = ({ color = 'white' }: Props): React.ReactElement => {
  return (
    <svg
      width="32"
      height="19"
      viewBox="0 0 32 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <line
        x1="0.908203"
        y1="1.92432"
        x2="31.2112"
        y2="1.92432"
        stroke={color}
        strokeWidth="3"
      />
      <line
        x1="0.908203"
        y1="9.5"
        x2="31.2112"
        y2="9.5"
        stroke={color}
        strokeWidth="3"
      />
      <line
        x1="0.908203"
        y1="17.0757"
        x2="31.2112"
        y2="17.0757"
        stroke={color}
        strokeWidth="3"
      />
    </svg>
  );
};

export default Component;
